import styles from "./BigTreeSection.module.css";
const BigTreeSection = () => {
  return (
    <div className={styles.homePageInner}>
      <div className={styles.frameParent}>
        <div className={styles.findYourNextCareerMoveWitWrapper}>
          <b className={styles.findYourNextContainer}>
            <p
              className={styles.findYourNext}
            >{`Find Your Next Career Move With `}</p>
            <p className={styles.findYourNext}>{`BigTree Recruitment  `}</p>
          </b>
        </div>
        <div className={styles.partnerWithUsForExpertRecWrapper}>
          <div className={styles.partnerWithUs}>
            Partner with us for expert recruitment in technology, construction,
            and finance industry.
          </div>
        </div>
        <div className={styles.findTheRightJobForYouWrapper}>
          <b className={styles.findTheRight}>Find the right job for you</b>
        </div>
      </div>
    </div>
  );
};

export default BigTreeSection;
