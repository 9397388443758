import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Contact.module.css";
const Contact = () => {
  const navigate = useNavigate();

  const onPhoneImageClick = useCallback(() => {
    window.location.href = "tel:+1 236 259 2697";
  }, []);

  const onMessageIconClick = useCallback(() => {
    window.location.href = "mailto:info@bigtreegroup.ca?subject=Hello there!";
  }, []);

  const onLinkdlnIconClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/bigtree-recruitment-group");
  }, []);

  const onFrameContainer1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <nav className={styles.contact}>
      <div className={styles.contactUs}>
        <div className={styles.contactUsParent}>
          <b className={styles.contactUs1}>CONTACT US</b>
          <b className={styles.b}>+1 236 259 2697</b>
          <b className={styles.infobigtreegroupca}>info@bigtreegroup.ca</b>
          <img
            className={styles.phoneIcon}
            alt=""
            src="/phone1@2x.png"
            onClick={onPhoneImageClick}
          />
          <img
            className={styles.messageIcon}
            alt=""
            src="/message1@2x.png"
            onClick={onMessageIconClick}
          />
        </div>
      </div>
      <img
        className={styles.linkdlnIcon}
        alt=""
        src="/linkdln1@2x.png"
        onClick={onLinkdlnIconClick}
      />
      <div className={styles.homeWrapper} onClick={onFrameContainer1Click}>
        <b className={styles.home}>Home</b>
      </div>
    </nav>
  );
};

export default Contact;
