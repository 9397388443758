import { useCallback } from "react";
import styles from "./ContactContainer.module.css";
const ContactContainer = () => {
  const onPhoneImageClick = useCallback(() => {
    window.location.href = "tel:+1 236 259 2697";
  }, []);

  const onMessageIconClick = useCallback(() => {
    window.location.href = "mailto:info@bigtreegroup.ca?subject=Hello there!";
  }, []);

  const onLinkdlnIconClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/bigtree-recruitment-group");
  }, []);

  return (
    <div className={styles.footer} data-scroll-to="footerContainer">
      <div className={styles.contactUs}>
        <div className={styles.contactUsParent}>
          <b className={styles.contactUs1}>CONTACT US</b>
          <b className={styles.b}>+1 236 259 2697</b>
          <b className={styles.infobigtreegroupca}>info@bigtreegroup.ca</b>
          <img
            className={styles.phoneIcon}
            alt=""
            src="/phone@2x.png"
            onClick={onPhoneImageClick}
          />
          <img
            className={styles.messageIcon}
            alt=""
            src="/message@2x.png"
            onClick={onMessageIconClick}
          />
        </div>
      </div>
      <img
        className={styles.linkdlnIcon}
        alt=""
        src="/linkdln@2x.png"
        onClick={onLinkdlnIconClick}
      />
    </div>
  );
};

export default ContactContainer;
