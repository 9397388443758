import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeSection from "../components/HomeSection";
import styles from "./Finance.module.css";
const Finance = () => {
  const navigate = useNavigate();

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='footerContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onPhoneImageClick = useCallback(() => {
    window.location.href = "tel:+1 236 259 2697";
  }, []);

  const onMessageIconClick = useCallback(() => {
    window.location.href = "mailto:info@bigtreegroup.ca?subject=Hello there!";
  }, []);

  const onLinkdlnIconClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/bigtree-recruitment-group");
  }, []);

  return (
    <div className={styles.finance}>
      <HomeSection
        onHomeTextClick={onHomeTextClick}
        onAboutUsTextClick={onAboutUsTextClick}
        onContactUsTextClick={onContactUsTextClick}
      />
      <img className={styles.image12Icon} alt="" src="/image-121@2x.png" />
      <div className={styles.financeWrapper}>
        <b className={styles.finance1}>Finance</b>
      </div>
      <div className={styles.financeInner}>
        <div className={styles.withinTheDynamicRealmOfFiWrapper}>
          <div className={styles.withinTheDynamic}>
            Within the dynamic realm of finance, BigTree Recruitment takes the
            lead as a specialized recruitment company, connecting exceptional
            talent with various finance roles across industries. From finance
            managers and investment analysts to accountants and financial
            advisors, we comprehend the pivotal positions that drive financial
            achievements. Our sourcing strategy seamlessly merges industry
            expertise, advanced sourcing techniques, and an extensive network,
            enabling us to identify and attract top-tier professionals. Our
            rigorous screening process encompasses meticulous evaluation of
            candidates' technical expertise, adaptability to high-pressure
            environments, and compatibility with the unique culture of financial
            organizations. By partnering with BigTree Recruitment, you gain
            unparalleled access to a curated pool of finance professionals who
            possess the requisite skills, knowledge, and determination to make
            an impactful contribution to your organization's financial triumphs.
            Trust us to be your strategic recruitment partner, building
            high-performance finance teams that consistently surpass
            expectations.
          </div>
        </div>
      </div>
      <div className={styles.footer} data-scroll-to="footerContainer">
        <div className={styles.contactUs}>
          <div className={styles.contactUsParent}>
            <b className={styles.contactUs1}>CONTACT US</b>
            <b className={styles.b}>+1 236 259 2697</b>
            <b className={styles.infobigtreegroupca}>info@bigtreegroup.ca</b>
            <img
              className={styles.phoneIcon}
              alt=""
              src="/phone2@2x.png"
              onClick={onPhoneImageClick}
            />
            <img
              className={styles.messageIcon}
              alt=""
              src="/message2@2x.png"
              onClick={onMessageIconClick}
            />
          </div>
        </div>
        <img
          className={styles.linkdlnIcon}
          alt=""
          src="/linkdln2@2x.png"
          onClick={onLinkdlnIconClick}
        />
      </div>
    </div>
  );
};

export default Finance;
