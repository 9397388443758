import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeSection from "../components/HomeSection";
import styles from "./Technology.module.css";
const Technology = () => {
  const navigate = useNavigate();

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='footerContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onCardTechnologyImageClick = useCallback(() => {
    window.open("/technology");
  }, []);

  const onPhoneImageClick = useCallback(() => {
    window.location.href = "tel:+1 236 259 2697";
  }, []);

  const onMessageIconClick = useCallback(() => {
    window.location.href = "mailto:info@bigtreegroup.ca?subject=Hello there!";
  }, []);

  const onLinkdlnIconClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/bigtree-recruitment-group");
  }, []);

  return (
    <div className={styles.technology}>
      <HomeSection
        onHomeTextClick={onHomeTextClick}
        onAboutUsTextClick={onAboutUsTextClick}
        onContactUsTextClick={onContactUsTextClick}
      />
      <img
        className={styles.cardTechnologyIcon}
        alt=""
        src="/card-technology1@2x.png"
        onClick={onCardTechnologyImageClick}
      />
      <div className={styles.technologyWrapper}>
        <b className={styles.technology1}>{`Technology `}</b>
      </div>
      <div className={styles.technologyInner}>
        <div className={styles.inTheEverEvolvingWorldOfWrapper}>
          <div className={styles.inTheEverEvolvingContainer}>
            <p className={styles.blankLine}>&nbsp;</p>
            <p className={styles.inTheEverEvolving}>
              In the ever-evolving world of technology, BigTree Recruitment
              stands as a premier recruitment company specializing in sourcing
              exceptional talent across various technology domains. From
              software development and data science to cybersecurity and cloud
              computing, we understand the diverse range of roles that drive
              technological innovation. Our sourcing strategy combines
              cutting-edge technology with human expertise, allowing us to
              identify passive candidates and access a vast network of top-tier
              professionals. What sets us apart from our competitors is our deep
              industry knowledge, extensive candidate screening process, and
              commitment to finding the perfect cultural fit. We go the extra
              mile to understand the intricacies of each role and the unique
              requirements of our clients, ensuring that we deliver high-caliber
              candidates who not only possess the requisite technical skills but
              also align seamlessly with the company's values and vision.
              Partner with BigTree Recruitment to secure the technology talent
              that fuels your organization's success.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.footer} data-scroll-to="footerContainer">
        <div className={styles.contactUs}>
          <div className={styles.contactUsParent}>
            <b className={styles.contactUs1}>CONTACT US</b>
            <b className={styles.b}>+1 236 259 2697</b>
            <b className={styles.infobigtreegroupca}>info@bigtreegroup.ca</b>
            <img
              className={styles.phoneIcon}
              alt=""
              src="/phone2@2x.png"
              onClick={onPhoneImageClick}
            />
            <img
              className={styles.messageIcon}
              alt=""
              src="/message2@2x.png"
              onClick={onMessageIconClick}
            />
          </div>
        </div>
        <img
          className={styles.linkdlnIcon}
          alt=""
          src="/linkdln1@2x.png"
          onClick={onLinkdlnIconClick}
        />
      </div>
    </div>
  );
};

export default Technology;
