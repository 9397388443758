import { useState, useCallback, useEffect } from "react";
import BigTreeSection from "../components/BigTreeSection";
import ContactContainer from "../components/ContactContainer";
import DrawerMenu from "../components/DrawerMenu";
import PortalDrawer from "../components/PortalDrawer";
import { useNavigate } from "react-router-dom";
import styles from "./HomePage.module.css";
const HomePage = () => {
  const [isDrawerMenuOpen, setDrawerMenuOpen] = useState(false);
  const navigate = useNavigate();

  const onHomeTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='homeText']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start" });
    }
  }, []);

  const onAboutUsTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='aboutUsPage']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onContactUsTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='footerContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const openDrawerMenu = useCallback(() => {
    setDrawerMenuOpen(true);
  }, []);

  const closeDrawerMenu = useCallback(() => {
    setDrawerMenuOpen(false);
  }, []);

  const onCardConstructionContainerClick = useCallback(() => {
    navigate("/construction");
  }, [navigate]);

  const onCardTechnologyContainerClick = useCallback(() => {
    navigate("/technology");
  }, [navigate]);

  const onCardFinanceContainerClick = useCallback(() => {
    navigate("/finance");
  }, [navigate]);

  return (
    <>
      <div className={styles.homePage}>
        <BigTreeSection />
        <ContactContainer />
        <img className={styles.logoIcon} alt="" src="/logo@2x.png" />
        <div className={styles.navbar}>
          <div className={styles.frame}>
            <div
              className={styles.home}
              data-scroll-to="homeText"
              onClick={onHomeTextClick}
            >
              Home
            </div>
            <div className={styles.aboutUs} onClick={onAboutUsTextClick}>
              About us
            </div>
            <div
              className={styles.contactUs}
              onClick={onContactUsTextClick}
            >{`Contact us `}</div>
          </div>
        </div>
        <button
          className={styles.hamburger}
          onClick={openDrawerMenu}
          data-animate-on-scroll
        >
          <img className={styles.image11Icon} alt="" src="/image-11@2x.png" />
        </button>
        <div className={styles.aboutUsPageParent}>
          <div className={styles.aboutUsPage} data-scroll-to="aboutUsPage">
            <div className={styles.heading}>
              <b className={styles.aboutUs1}>About Us</b>
            </div>
            <div className={styles.aboutUs2}>
              <div className={styles.aboutUsTextWrapper}>
                <div className={styles.aboutUsTextContainer}>
                  <p
                    className={styles.atBigtreeRecruitment}
                  >{`At BigTree Recruitment, we specialize in the fields of Technology, Finance, and Construction. With our extensive industry knowledge and expertise, we are dedicated to connecting exceptional talent with top-tier organizations. Our unique approach centers around conducting multiple screening rounds to ensure we deliver high-quality candidates to our clients. `}</p>
                  <p className={styles.atBigtreeRecruitment}>&nbsp;</p>
                  <p className={styles.atBigtreeRecruitment}>
                    We pride ourselves on our ability to identify passive
                    candidates who may not be actively seeking new opportunities
                    but possess exceptional skills and potential. Our focus
                    extends beyond technical qualifications as we strongly
                    believe in finding the perfect cultural fit for both our
                    candidates and the companies we serve. We strive to exceed
                    expectations by consistently delivering outstanding
                    professionals, who not only possess the necessary skill set
                    but also seamlessly integrate into the organizational
                    culture.
                  </p>
                  <p className={styles.atBigtreeRecruitment}>{` `}</p>
                  <p className={styles.atBigtreeRecruitment}>&nbsp;</p>
                  <p className={styles.atBigtreeRecruitment}>
                    Trust us to find your ideal match in the ever-evolving world
                    of technology, finance, and construction.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.cardConstruction}
            onClick={onCardConstructionContainerClick}
          >
            <div className={styles.construction}>Construction</div>
          </div>
          <div
            className={styles.cardTechnology}
            onClick={onCardTechnologyContainerClick}
          >
            <div className={styles.construction}>Technology</div>
          </div>
          <div
            className={styles.cardFinance}
            onClick={onCardFinanceContainerClick}
          >
            <div className={styles.finance}>Finance</div>
          </div>
        </div>
      </div>
      {isDrawerMenuOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeDrawerMenu}
        >
          <DrawerMenu onClose={closeDrawerMenu} />
        </PortalDrawer>
      )}
    </>
  );
};

export default HomePage;
