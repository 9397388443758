import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeSection from "../components/HomeSection";
import styles from "./Construction.module.css";
const Construction = () => {
  const navigate = useNavigate();

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='footerContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onPhoneImageClick = useCallback(() => {
    window.location.href = "tel:+1 236 259 2697";
  }, []);

  const onMessageIconClick = useCallback(() => {
    window.location.href = "mailto:info@bigtreegroup.ca?subject=Hello there!";
  }, []);

  const onLinkdlnIconClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/bigtree-recruitment-group");
  }, []);

  return (
    <div className={styles.construction}>
      <HomeSection
        onHomeTextClick={onHomeTextClick}
        onAboutUsTextClick={onAboutUsTextClick}
        onContactUsTextClick={onContactUsTextClick}
      />
      <img className={styles.image12Icon} alt="" src="/image-12@2x.png" />
      <div className={styles.constructionWrapper}>
        <b className={styles.construction1}>Construction</b>
      </div>
      <div className={styles.constructionInner}>
        <div className={styles.inTheDynamicWorldOfConstrWrapper}>
          <div className={styles.inTheDynamic}>
            In the dynamic world of construction, BigTree Recruitment
            specializes in connecting exceptional talent with construction firms
            across various sectors. From project managers and civil engineers to
            architects and construction managers, we understand the critical
            roles that drive successful construction projects. Our sourcing
            strategy combines industry expertise, extensive networking, and
            cutting-edge technology to identify and attract top talent. What
            sets us apart from our competitors is our comprehensive screening
            process, where we thoroughly evaluate candidates for their technical
            proficiency, project management skills, and ability to thrive in a
            fast-paced construction environment. Moreover, we recognize that
            cultural fit is crucial in the construction industry, and we
            diligently assess candidates' alignment with the organizational
            culture to ensure seamless integration. By partnering with BigTree
            Recruitment, you gain access to a curated pool of high-caliber
            professionals who have the expertise and drive to deliver
            exceptional construction projects. Let us be your trusted
            recruitment partner in building high-performance teams that redefine
            success in the construction industry.
          </div>
        </div>
      </div>
      <div className={styles.footer} data-scroll-to="footerContainer">
        <div className={styles.contactUs}>
          <div className={styles.contactUsParent}>
            <b className={styles.contactUs1}>CONTACT US</b>
            <b className={styles.b}>+1 236 259 2697</b>
            <b className={styles.infobigtreegroupca}>info@bigtreegroup.ca</b>
            <img
              className={styles.phoneIcon}
              alt=""
              src="/phone2@2x.png"
              onClick={onPhoneImageClick}
            />
            <img
              className={styles.messageIcon}
              alt=""
              src="/message2@2x.png"
              onClick={onMessageIconClick}
            />
          </div>
        </div>
        <img
          className={styles.linkdlnIcon}
          alt=""
          src="/linkdln2@2x.png"
          onClick={onLinkdlnIconClick}
        />
      </div>
    </div>
  );
};

export default Construction;
